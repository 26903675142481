import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import image from "../assets/painterImage.jpg";

const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "About Me | Pouring Passions";
  }, []);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="desktop">
        <div className="left">
          <motion.h1
            ref={ref}
            variants={{
              hidden: { opacity: 0, y: 100, scale: 0.5 },
              visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
          >
            ABOUT ME
          </motion.h1>
          <motion.p
            variants={{
              hidden: { opacity: 0, y: 100, scale: 0.6 },
              visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
          >
            They say the artist is silent so their work can be loud. The story
            behind each piece is what makes my artworks so valuable and unique,
            they’re not just a sight for sore eyes, but the main purpose is for
            people to relate and resonate with my art on a deeper level! <br />
            <br />
            While my artworks seem to end up super abstract most of time, each
            of them are inspired by events that made me experience any type of
            emotion. I paint collections of things I love, events that shatter
            my heart and all that rollercoaster of emotions is portrayed by the
            pouring paints and textures. When I’m drowning in emotion, my only
            weapon is a brush and a tube of paint! <br /> <br />
            Art in general evokes who we are as humans. I’m aware my art isn’t
            everyone’s piece of cake, and that’s okay! First and foremost I
            create for myself and people loving and appreciating my pieces only
            makes the journey even more wholesome and rewarding!
          </motion.p>
        </div>
        <img src={image} alt="" />
      </div>
      <div className="mobile">
        <div className="top">
          <h1>ABOUT ME</h1>
          <img src={image} alt="" />
        </div>
        <motion.p
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
        >
          They say the artist is silent so their work can be loud. The story
          behind each piece is what makes my artworks so valuable and unique,
          they’re not just a sight for sore eyes, but the main purpose is for
          people to relate and resonate with my art on a deeper level! <br />
          <br />
          While my artworks seem to end up super abstract most of time, each of
          them are inspired by events that made me experience any type of
          emotion. I paint collections of things I love, events that shatter my
          heart and all that rollercoaster of emotions is portrayed by the
          pouring paints and textures. When I’m drowning in emotion, my only
          weapon is a brush and a tube of paint! <br /> <br />
          Art in general evokes who we are as humans. I’m aware my art isn’t
          everyone’s piece of cake, and that’s okay! First and foremost I create
          for myself and people loving and appreciating my pieces only makes the
          journey even more wholesome and rewarding!
        </motion.p>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 5em 7%;
  .desktop {
    font-size: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    .left {
      width: 60%;
      h1 {
        font-family: "The Seasons", sans-serif;
        font-weight: 300;
      }
      p {
        font-size: 20px;
      }
    }
    img {
      width: 35%;
      border-radius: 20px;
      border: 2px solid #c4bdbd;
      box-shadow: 2px 0 17px 5px #c0b6b4;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 900px) {
    padding: 6em 7%;
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        h1 {
          font-size: 14vw;
          font-family: "The Seasons", sans-serif;
          font-weight: 300;
          text-align: end;
        }
        img {
          width: 40%;
          border-radius: 20px;
          border: 2px solid #c4bdbd;
          box-shadow: 2px 0 17px 5px #c0b6b4;
        }
      }
      p {
        margin-top: 2em;
      }
    }
  }
  @media (max-width: 450px) {
    h1 {
      font-size: 16vw;
      margin-bottom: 15px;
    }
  }
`;

export default AboutMe;
