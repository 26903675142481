import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bannerImg from "../../assets/bannerImg.jpg";
import { motion, useAnimation, useInView } from "framer-motion";

const Banner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <img src={bannerImg} alt="" />
      <span>
        {" "}
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.5 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          Poetry in paint,
          <br /> silent words <br />
          speak
        </motion.h1>
        <motion.p
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, atque?
        </motion.p>
        <motion.button
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
        >
          EXPLORE ART
        </motion.button>
      </span>
    </Container>
  );
};
const Container = styled.div`
  height: 90vh;
  max-height: 800px;
  img {
    width: 27vw;
    right: 12%;
    opacity: 0.3;
    top: 15%;
    position: absolute;
    z-index: 1;
    transform: rotate(7deg);
  }
  span {
    position: absolute;
    top: 16%;
    padding: 1em 10%;
    z-index: 1;
    h1 {
      font-size: 8vw;
      font-family: "Josefin Sans";
      font-weight: 700;
      -webkit-text-fill-color: transparent;
      background-image: var(--bgTextGradient);
      -webkit-background-clip: text;
      background-clip: text;
    }
    p {
      font-size: 18px;
      margin-top: 10px;
    }
    button {
      background: #574e4e;
      color: white;
      font-family: "Josefin Sans", sans-serif;
      padding: 17px 20px 15px 20px;
      font-size: 20px;
      border: none;
      margin-top: 1em;
    }
  }
  @media (max-width: 700px) {
    img {
      top: 7em;
    }
    span {
      text-align: center;
      top: 15%;
      h1 {
        font-size: 11vw;
      }
    }
  }
  @media (max-width: 500px) {
    height: 70vh;
    img {
      width: 40vw;
      opacity: 0.2;
    }
    span {
      text-align: center;
      top: 15%;
      h1 {
        font-size: 14vw;
      }
    }
  }
`;

export default Banner;
