import styled from "styled-components";
import { motion, useTransform, useScroll } from "framer-motion";
import { useRef, useEffect, useState } from "react";
import image1 from "../../assets/homeAssets/workExample.jpg";
import image2 from "../../assets/homeAssets/workExample2.jpg";
import image3 from "../../assets/homeAssets/workExample3.jpg";
import image4 from "../../assets/homeAssets/workExample4.jpg";
import image5 from "../../assets/homeAssets/workExample5.jpg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const OurWork = () => {
  return (
    <Container>
      <ScrollIndicator>
        <IndicatorText>MY WORK</IndicatorText>
      </ScrollIndicator>
      <HorizontalScrollCarousel />
    </Container>
  );
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const isMobile = useIsMobile(); // Use the custom hook to check for mobile device

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  // Adjust the transform percentage based on whether the device is mobile
  const endPercentage = isMobile
    ? `-${(cards.length - 1) * 112}%`
    : `-${(cards.length - 1) * 27}%`;

  const x = useTransform(scrollYProgress, [0, 1], ["0%", endPercentage]);

  return (
    <CarouselSection ref={targetRef}>
      <StickyContainer>
        <HorizontalContainer style={{ x }}>
          {cards.map((card, index) => (
            <>
              <Card card={card} key={index} />
              <div>
                <MdOutlineKeyboardArrowRight className="icon" />
              </div>
            </>
          ))}
        </HorizontalContainer>
        <p className="scrollP">Keep Scrolling Down ↓</p>
      </StickyContainer>
    </CarouselSection>
  );
};

const Card = ({ card, title }) => {
  return (
    <CardContainer>
      <CardImageContainer>
        <CardImage style={{ backgroundImage: `url(${card.url})` }} />
      </CardImageContainer>
      <CardOverlay>
        <CardTitle>{title}</CardTitle>
      </CardOverlay>
    </CardContainer>
  );
};

export default OurWork;

const cards = [
  {
    url: image1,
    title: "Work Example",
    id: 1,
  },
  {
    url: image2,
    title: "Work Example",
    id: 2,
  },
  {
    url: image3,
    title: "Work Example",
    id: 3,
  },
  {
    url: image4,
    title: "Work Example",
    id: 4,
  },
  {
    url: image5,
    title: "Work Example",
    id: 5,
  },
];

// Styled Components
const Container = styled.div`
  background-color: var(--primaryBackgroundColor);
  margin-top: 5em;
`;
const ScrollIndicator = styled.div`
  display: flex;
  height: 12rem;
  align-items: center;
  justify-content: center;
`;

const IndicatorText = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  color: var(--fontPrimaryColor);
  font-size: 7vw;
  font-weight: 200;
  font-family: "The Seasons", serif;
  @media (max-width: 600px) {
    font-size: 15vw;
  }
`;

const CarouselSection = styled.section`
  position: relative;
  height: 220vh;
  background-color: var(--primaryBackgroundColor);
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 7em;
  height: auto;
  overflow: hidden;
  .scrollP {
    text-align: center;
    margin-top: 1%;
  }
`;

const HorizontalContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  .icon {
    font-size: 3em;
    color: var(--fontSecondaryColor);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const CardContainer = styled.div`
  position: relative;
  height: 37vw;
  width: 37vw;
  min-width: 200px; // Ensure cards don’t get too small on very narrow devices
  min-height: 200px;
  max-height: 90vh;
  flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 4px;
  margin-right: 1rem;
  @media (max-width: 550px) {
    width: 90%;
    height: 60vh;
    margin-left: 2%;
  }
`;

const CardImageContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
  filter: brightness(0.7);
  overflow: hidden;
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s;

  ${CardContainer}:hover & {
    transform: scale(1.1);
  }
`;

const CardOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

const CardTitle = styled.p`
  background: #00000036;
  padding: 10px 20px;
  font-size: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  backdrop-filter: blur(4px);
`;
const CardDesc = styled.p`
  background: #00000036;
  padding: 10px 20px;
  font-size: 1em;
  text-align: center;
  font-weight: 300;
  color: white;
  backdrop-filter: blur(4px);
`;
