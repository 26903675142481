import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import paintingsData from "../components/paintingsData"; // Assuming the data is here
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import emailjs from "@emailjs/browser";

const CheckoutPage = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation = useAnimation();
  const { name } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("Kosovë");
  const [shippingPrice, setShippingPrice] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = `Buy ${name} | Pouring Passions`;
  }, []);

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
  }, [isInView, animation]);

  useEffect(() => {
    const productData = paintingsData.find((item) => item.name === name);
    setProduct(productData);
  }, [name]);

  const handleShippingChange = (e) => {
    const selectedOption = e.target.value;
    if (selectedOption === "Kosovë") {
      setShippingPrice(0);
    } else if (selectedOption === "Shqipëri") {
      setShippingPrice(5);
    } else if (selectedOption === "Maçedoni") {
      setShippingPrice(5);
    } else {
      setShippingPrice(0);
    }
  };

  const handleOrder = (e) => {
    e.preventDefault();

    const form = e.target;

    emailjs
      .sendForm(
        "service_gujw8oj",
        "template_lz3971a",
        form,
        "XM2-lZ-5GQGXadVM0"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Order placed successfully!");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to place order.");
        }
      );
  };
  const calculateTotalPrice = (productPrice, shippingPrice) => {
    return productPrice + shippingPrice;
  };

  if (!product) {
    return <div>Loading...</div>;
  }
  const totalPrice = calculateTotalPrice(product.price, shippingPrice);

  return (
    <Container>
      <div className="left">
        <div className="item">
          <img src={product.image} alt="" />
          <span>
            <h3>{product.name}</h3>
            <h4>{product.size}</h4>
            <h4>{product.year}</h4>
            <h2>€{product.price}</h2>
          </span>
        </div>
        <div className="total">
          <span>
            <h3>Subtotal</h3>
            <h2>€{product.price}</h2>
          </span>
          <span>
            <h3>Transporti</h3>
            {shippingPrice === 0 ? (
              <h2>FALAS</h2>
            ) : (
              <h2>€{shippingPrice.toFixed(2)}</h2>
            )}
          </span>

          <hr />
          <span>
            <h2>Total</h2>
            <h1>€{totalPrice.toFixed(2)}</h1>
          </span>
        </div>
      </div>
      <hr />
      <form ref={ref} className="right" onSubmit={handleOrder}>
        <div className="contact">
          <label>Contact</label>
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            required
          />
          <input type="phoneNumber" name="phone" placeholder="Phone Number" />
          <div className="delivery">
            <label>Delivery</label>
            <span>
              <input
                name="username"
                type="text"
                placeholder="Full Name"
                required
              />
              <select
                onChange={(e) => {
                  handleShippingChange(e);
                  setSelectedCountry(e.target.value);
                }}
                name="country"
                required
              >
                <option value="Kosovo">Kosovë</option>
                <option value="Shqipëri">Shqipëri</option>
                <option value="Maçedoni">Maçedoni</option>
              </select>
            </span>
            <span>
              <input name="city" type="text" placeholder="City" required />
              {/* <input
                name="postalCode"
                type="number"
                placeholder="Postal Code"
                required
              /> */}
              <input
                name="address"
                type="text"
                placeholder="Address"
                required
              />
            </span>
            {/* <span>
              <input name="street" type="text" placeholder="Street" required />
              <input name="number" type="text" placeholder="Number, Apt, Suite..." />
            </span> */}
            <textarea
              name="message"
              cols="10"
              rows="4"
              placeholder="Message (Optional)"
            ></textarea>
            <input type="hidden" name="paintingName" value={product.name} />
            <input type="hidden" name="paintingPrice" value={product.price} />
            <button type="submit">PLACE ORDER</button>
          </div>
        </div>
      </form>
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
  margin: auto;
  max-width: 1500px;
  hr {
    height: 70vh;
    border: 1px solid #dfdfdf;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex: 3;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #e4e0db;
      width: 100%;
      text-align: end;
      img {
        height: 200px;
        width: auto;
        border-radius: 0px;
        object-fit: contain;
        background-color: white;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        h4 {
          text-transform: capitalize;
          font-size: 17px;
          font-weight: 300;
          color: gray;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          span {
            color: #353432;
            font-weight: 500;
          }
        }
        h3 {
          font-weight: 500;
          color: #575250;
        }
        h2 {
          margin-top: 10px;
          font-weight: 500;
          color: #8d7f7a;
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-top: 3em;
      hr {
        border: none;
        height: 1px;
        border-bottom: 1px solid #dad3d0;
      }
      span {
        display: flex;
        justify-content: space-between;
        h3 {
          font-weight: 400;
          color: #8d7f7a;
        }
        h2 {
          font-weight: 500;
          font-size: 19px;
          color: #9b8983;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          color: #575250;
        }
      }
    }
  }
  .right {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 2em;
    div {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    span {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    input,
    textarea,
    select {
      font-family: "Montserrat", sans-serif;
      outline: none;
      border: 1px solid #dfdfdf;
      font-size: 17px;
      border-radius: 7px;
      padding: 14px;
      background: none;
      color: gray;
      width: 100%;
    }
    label {
      font-weight: 500;
      font-size: 20px;
    }
    .shipping {
      background-color: var(--secondaryBackgroundColor);
      padding: 20px 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        h4 {
          font-weight: 300;
        }
      }
    }
    .payBtn {
      margin: 1em 0;
      font-size: 20px;
      padding: 13px 0;
    }
    .paypalBtns {
      display: flex;
    }
    button {
      margin: auto;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      background: #5e524e;
      color: white;
      border: none;
      padding: 10px 14px;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        box-shadow: 0 0 10px 2px #91827e;
      }
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 2em;
    hr {
      height: 1px;
    }
  }
  @media (max-width: 800px) {
    .left {
      .item {
        img {
          width: 45%;
        }
      }
    }
  }
`;

export default CheckoutPage;
