import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import img1 from "../assets/paintingsAssets/p17.jpg";
import img2 from "../assets/paintingsAssets/p19.jpg";
import img3 from "../assets/paintingsAssets/p13.jpg";
import paintingsData from "../components/paintingsData";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";

const Paintings = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Paintings | Pouring Passions";
  }, []);
  return (
    <Container>
      <Swiper
        className="swiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={4}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        <SwiperSlide className="slide">
          <div className="left">
            <h1>What keeps my heart awake is colorful silence.</h1>
            <p>- Claude Monet</p>
          </div>
          <img src={img1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide2">
          <div className="left">
            <h1>Art washes away from the soul and dust of everyday life.</h1>
            <p>- Pablo Picasso</p>
          </div>
          <img src={img2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide3">
          <div className="left">
            <h1>
              When it comes to art, it's important not to hide the madness.
            </h1>
            <p>- Atticus</p>
          </div>
          <img src={img3} alt="" />
        </SwiperSlide>
      </Swiper>
      <div className="paintings">
        {paintingsData.map((item) => (
          <div className="item">
            <Link to={`/paintings/${item.name}`}>
              <img src={item.image} alt="" />
            </Link>
            <div className="details">
              <h3>{item.name}</h3>
              <h2>€{item.price}</h2>
              {item.sold ? (
                <button>SOLD</button>
              ) : (
                <Link to={`/checkout/${item.name}`}>
                  <button>BUY NOW</button>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  margin: 5em 0;
  .swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .slide {
      padding: 5%;
      width: 85%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 8%;
      background: #ddd6d255;
      .left {
        width: 56%;
        h1 {
          color: #464646;
          font-size: 5vw;
          font-family: "The Seasons", sans-serif;
          font-weight: 300;
        }
      }
      img {
        height: 100%;
        max-width: 50%;
        object-fit: contain;
      }
    }
  }
  .paintings {
    padding: 7em 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em 2em;
    flex-wrap: wrap;
    .item {
      height: 70vh;
      max-height: 600px;
      img {
        height: 80%;
        max-height: 600px;
        min-width: 30%;
        cursor: pointer;
        transition: ease-in-out 0.4s;
        &:hover {
          transform: scale(1.05);
        }
      }
      .details {
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;
        text-align: end;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;
        padding: 10px 1em 0 0;
        h3 {
          color: #85766e;
          font-weight: 400;
        }
        h2 {
          color: #4b4643;
          font-weight: 500;
          font-size: 18px;
        }
        button {
          font-family: "Montserrat", sans-serif;
          color: #85766e;
          padding: 10px 30px;
          background: none;
          border: 1px solid #b9aea7;
          cursor: pointer;
          transition: 0.4s;
          &:hover {
            background: #81745a;
            color: white;
          }
        }
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 5%;
    height: 5px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #ada491; /* Change to your desired active color */
    opacity: 1;
  }
  @media (max-width: 600px) {
    .swiper {
      height: auto;
      .slide {
        height: 80vh;
        gap: 3%;
        flex-direction: column;
        .left {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 8vw;
          }
        }
        img {
          height: auto;
          max-height: 60%;
          max-width: 100%;
        }
      }
      .slide3 {
        .left {
          h1 {
            font-size: 5.4vw;
          }
        }
      }
    }
  }
  @media (max-width: 450px) {
    .swiper {
      .slide {
        width: 100%;
        height: 63vh;
        .left {
          h1 {
            font-size: 10vw;
          }
        }
      }
      .slide3 {
        .left {
          h1 {
            font-size: 9vw;
          }
        }
      }
    }
  }
  @media (max-width: 510px) {
    .paintings {
      .item {
        width: 100%;
        height: auto;
        img {
          height: auto;
          width: 100%;
          min-width: 100px;
        }
      }
    }
  }
`;

export default Paintings;
