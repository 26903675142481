import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../components/homeComponents/Banner";
import Stats from "../components/homeComponents/Stats";
import Quotes from "../components/homeComponents/Quotes";
import OurWork from "../components/homeComponents/OurWork";
import FAQ from "../components/homeComponents/FAQ";
import ContactMe from "../components/homeComponents/ContactMe";
import NewBanner from "../components/homeComponents/NewBanner";
import BTS from "../components/homeComponents/BTS";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Pouring Passions | Poetry in Paint";
  }, []);
  return (
    <Container>
      <NewBanner />
      {/* <Banner /> */}
      <Stats />
      <Quotes />
      <OurWork />
      <BTS />
      <FAQ />
      <ContactMe />
    </Container>
  );
};
const Container = styled.div`
  padding: 0%;
  /* overflow-x: hidden; */
`;

export default Home;
