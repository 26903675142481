import p1 from "../assets/paintingsAssets/p1.jpg";
import p2 from "../assets/paintingsAssets/p2.jpg";
import p3 from "../assets/paintingsAssets/p3.jpg";
import p4 from "../assets/paintingsAssets/p4.jpg";
import p5 from "../assets/paintingsAssets/p5.jpg";
import p6 from "../assets/paintingsAssets/p6.jpg";
import p7 from "../assets/paintingsAssets/p7.jpg";
import p8 from "../assets/paintingsAssets/p8.jpg";
import p9 from "../assets/paintingsAssets/p9.jpg";
import p10 from "../assets/paintingsAssets/p10.jpg";
import p11 from "../assets/paintingsAssets/p11.jpg";
import p12 from "../assets/paintingsAssets/p12.jpg";
import p13 from "../assets/paintingsAssets/p13.jpg";
import p14 from "../assets/paintingsAssets/p14.jpg";
import p15 from "../assets/paintingsAssets/p15.jpg";
import p16 from "../assets/paintingsAssets/p16.jpg";
import p17 from "../assets/paintingsAssets/p17.jpg";
import p18 from "../assets/paintingsAssets/p18.jpg";
import p19 from "../assets/paintingsAssets/p19.jpg";
import p20 from "../assets/paintingsAssets/p20.jpg";
import p21 from "../assets/paintingsAssets/p21.jpg";
import p22 from "../assets/paintingsAssets/p22.jpg";
import p23 from "../assets/paintingsAssets/p23.jpg";
import p24 from "../assets/paintingsAssets/p24.png";
import p25 from "../assets/paintingsAssets/p25.jpg";
import p26 from "../assets/paintingsAssets/p26.png";
import p16_1 from "../assets/paintingsAssets/p16_1.jpg";
import p16_2 from "../assets/paintingsAssets/p16_2.jpg";
import p16_3 from "../assets/paintingsAssets/p16_3.jpg";
import p17_1 from "../assets/paintingsAssets/p17_1.jpg";
import p17_2 from "../assets/paintingsAssets/p17_2.jpg";
import p17_3 from "../assets/paintingsAssets/p17_3.jpg";
import p24_1 from "../assets/paintingsAssets/p24_1.jpg";
import p24_2 from "../assets/paintingsAssets/p24_2.jpg";
import p18_1 from "../assets/paintingsAssets/p18_1.jpg";
import p20_1 from "../assets/paintingsAssets/p20_1.jpg";
import p20_2 from "../assets/paintingsAssets/p20_2.jpg";
import p20_3 from "../assets/paintingsAssets/p20_3.PNG";
import p23_1 from "../assets/paintingsAssets/p23_1.jpg";
import p23_2 from "../assets/paintingsAssets/p23_2.jpg";
import p21_1 from "../assets/paintingsAssets/p21_1.jpg";
import p21_2 from "../assets/paintingsAssets/p21_2.jpg";
import p21_3 from "../assets/paintingsAssets/p21_3.jpg";
import p22_1 from "../assets/paintingsAssets/p22_1.jpg";
import p25_1 from "../assets/paintingsAssets/p25_1.jpg";
import p25_2 from "../assets/paintingsAssets/p25_2.jpg";
import p25_3 from "../assets/paintingsAssets/p25_3.png";

const paintingsData = [
  {
    id: "painting25",
    name: "Summer",
    size: "2X 110x70",
    image: p25_1,
    images: [p25_3, p25, p25_1, p25_2],
    paintingType: "Acrylic on Canvas",
    year: 2024,
    price: 759.99,
    priceInt: 1899.99,
    sold: true,
  },
  {
    id: "painting21",
    name: "The Earthquake III",
    size: "100x120",
    image: p21_1,
    images: [p21, p21_1, p21_2, p21_3],
    paintingType: "Mixed Media On Canvas",
    year: 2024,
    price: 599.99,
    priceInt: 1499.99,
    description:
      "The terrifying sounds of the earthquake shook everyone, and the strength of it shook the ground to its core.     ",
  },

  {
    id: "painting17",
    name: "Under The Rubbles",
    size: "100x140",
    image: p17_1,
    images: [p17, p17_1, p17_2, p17_3],
    paintingType: "Mixed Media On Canvas",
    year: 2023,
    price: 619.99,
    priceInt: 2299.99,
    description:
      "Stuck under the rubbles, the bodies and souls of the victims slowly fade away into darkness, leaving behind all the pain and suffering.",
    sold: true,
  },
  {
    id: "painting22",
    name: "Neutrals",
    size: "80x120",
    image: p22_1,
    images: [p22, p22_1],
    paintingType: "Mixed Media On Canvas",
    year: 2024,
    price: 499.99,
    priceInt: 1699.99,
    sold: true,
  },
  {
    id: "painting23",
    name: "Splash",
    size: "80x100",
    image: p23_2,
    images: [p23, p23_1, p23_2],
    paintingType: "Acrylic On Canvas",
    year: 2023,
    price: 599.99,
    priceInt: 1799.99,
    sold: true,
  },
  {
    id: "painting18",
    name: "Ghost City",
    size: "100x120",
    image: p18_1,
    images: [p18, p18_1],
    paintingType: "Combined Technique on Canvas ",
    year: 2023,
    price: 559.99,
    priceInt: 2099.99,
    description:
      "No more magnificent buildings with all their glory. No more lights and life. The vibrations turned the city upside down and there’s more of it underneath the ground than above it.    ",
  },
  {
    id: "painting24",
    name: "Freedom",
    size: "60x90",
    image: p24_1,
    images: [p24, p24_1, p24_2],
    paintingType: "Acrylic On Canvas",
    year: 2023,
    price: 689.99,
    priceInt: 2599.99,
    description:
      "I can’t decide if I’m flying, swimming, I just know that whatever the activity might be, I feel more alive and free then I’ve ever been…",
  },
  {
    id: "painting16",
    name: "The Traveler",
    size: "140x100",
    image: p16_3,
    images: [p16, p16_1, p16_2, p16_3],
    paintingType: "Acrylic on Canvas",
    year: 2023,
    price: 539.99,
    priceInt: 1599.99,
    description:
      "We are all travelers in this world, we will be gone without leaving a trace. It’s out responsibility to take care of our journey and prepare for the next one. Our memory will be alive as long as the existence of our loved ones, so as shortly as that time may last, may we leave sweetest footprints on their hearts…    ",
    sold: true,
  },
  {
    id: "painting19",
    name: "The Earthquake",
    size: "100x120",
    image: p19,
    paintingType: "Mixed Media On Canvas",
    year: 2023,
    price: 619.99,
    priceInt: 2300.99,
    description:
      "The terrifying sounds of the earthquake shook everyone, and the strength of it shook the ground to its core.     ",
    sold: true,
  },
  {
    id: "painting20",
    name: "The Earthquake II",
    size: "100x120",
    image: p20_3,
    images: [p20, p20_1, p20_2, p20_3],
    paintingType: "Mixed Media On Canvas",
    year: 2023,
    price: 579.99,
    priceInt: 2099.99,
    description:
      "As the people’s worlds come crashing down, so do their homes. Stones falling from the sky like rain, ruing anything and everything they come across.",
  },
  {
    id: "painting14",
    name: "Doodle",
    size: "30x40",
    image: p14,
    paintingType: "Acrylic on Canvas",
    year: 2022,
    price: 99.99,
    priceInt: 209.99,
    description:
      "Doodling doesn’t have a age limit. It’s proven to relax your mind and release you from the negative thoughts. Many think it’s childish, so what? Embrace your inner child and let it thrive, make up for all the things you were denied of during your childhood!    ",
  },
  {
    id: "painting15",
    name: "Depth",
    size: "100x120",
    image: p15,
    paintingType: "Acrylic on Canvas",
    year: 2022,
    price: 579.99,
    priceInt: 1799.99,
    description:
      "Swept away by the strong waves, not having anything significant to hold on to and feeling your throat burn from the salt. Sounds like a nightmare, something we experience every once in a while. However, knowing we’re not alone, we must resist, by refusing to drown in the ocean of life…     ",
  },
  {
    id: "painting13",
    name: "Chaos",
    size: "30x40",
    image: p13,
    paintingType: "Acrylic On Canvas",
    year: 2022,
    price: 129.99,
    priceInt: 309.99,
    description:
      "Getting lost in the chaos called life is a universal experience. However difficult that struggle may be, however suffocated we may feel, after a while, when we open our eyes freely and use a different point of view, we realize things weren’t as bad as we made them out to be. We just approached the situation with the burden of the moment. There was always beauty, even if we failed to see it at the time.     ",
    sold: true,
  },
  {
    id: "painting12",
    name: "Growth",
    size: "100x120",
    image: p12,
    paintingType: "Acrylic On Canvas",
    year: 2022,
    price: 499.99,
    priceInt: 1199.99,
    description:
      "Growth is a part of life. Sometimes we grow apart, sometimes we grow together, but it’s important to embrace the scars of the past, no matter what the present introduces us with!    ",
  },
  {
    id: "painting6",
    name: "Wounded",
    size: "30x45",
    image: p6,
    paintingType: "Acrylic on Canvas",
    year: 2020,
    price: 179.99,
    priceInt: 379.99,
    description:
      "Bullets are not the only weapons out there. They may be the most common one, the ones that leave the most visible scars, but no one talks about the bullets shot by one’s mouth. Those that leave permanent wounds, only to be overlooked in a way that’s certainly frustrating. They say that time heals everything, but how long to we have to wait to feel whole again?",
  },
  {
    id: "painting26",
    name: "The Massacre - Set",
    size: "30x45",
    image: p26,
    paintingType: "Acrylic on Canvas",
    year: 2020,
    price: 879.99,
    priceInt: 2099.99,
    description:
      "The whole collection was based of the massacre of Srebrenica, one of the most ruthless acts known to mankind. Considering most of the killing was committed in civilians, including new borns, children, women and elders, this Massacre is also recognized as one of the biggest genocides to happen in the history of Europe.  I am deeply touched by these kind of historical events, by the killings of the innocent, especially when it is done on unjustifiable reasons. What touches me the most is all the souls that didn’t get a chance to live, all the babies that didn’t get the chance to enjoy this world, all of the flowers that didn’t get to bloom. Since doing something major is out of my abilities, I stick and mourn with what I know best-painting!",
    sold: true,
  },
  {
    id: "painting2",
    name: "Shattered",
    size: "80x100px",
    image: p2,
    paintingType: "Acrylic and mixed media on canvas",
    year: 2020,
    price: 379.99,
    priceInt: 2199.99,
    description:
      "One of the few pieces I made during quarantine, and it portrays the pain of the situation. The hands represent each of us, the glass represents the difficulty of the situation and the blood flow shows us sacrificing our desires and needs in order to keep everyone safe. This painting shows that sometimes the light comes from the good we do in order to protect others. Sacrificing parts of yourself for a greater cause it’s the best thing you can do for the others, so every amount of pain is worth it in the end!",
  },
  {
    id: "painting1",
    name: "Depth 2",
    size: "120x800px",
    image: p1,
    paintingType: "Acrylic and mixed media on canvas",
    year: 2020,
    price: 579.99,
    priceInt: 2099.99,
  },
  {
    id: "painting11",
    name: "Artifact",
    size: "80x100",
    image: p11,
    paintingType: "Acrylic on Canvas",
    year: 2021,
    price: 409.99,
    priceInt: 849.99,
    description:
      "It’s very important to preserve the culture and work on keeping the old alive. Finding art- sculptures, paintings or building remains of the past can awaken in you something you didn’t know existed. Sometimes a certain piece just speaks to you and when it does, listen carefully    ",
  },
  {
    id: "painting10",
    name: "Holding On",
    size: "60x90",
    image: p10,
    paintingType: "Acrylic on Canvas",
    year: 2021,
    price: 319.99,
    priceInt: 709.99,
    description:
      "Holding on or Letting go? That’s the most common way we respond to the slightest difficulty. Even though that’s the confusing part, the solution always lies in the heart. It is kind of cliche, but when you know, you know, there’s no reason to pressure yourself or rush into a decision, the right one will eventually come to you, you just need to have faith!    ",
  },
  {
    id: "painting9",
    name: "Abandoned",
    size: "30x40",
    image: p9,
    paintingType: "Acrylic on canvas",
    year: 2021,
    price: 129.99,
    priceInt: 269.99,
    description:
      "We strive to bloom. And what makes that happen, apart from ambition is the support of our surroundings. Without that we turn into lifeless bodies, with no will to live. So what happens when we feel all alone, when we feel like everyone has left us, when the misery cripples our hearts?!  We must turn to God, and there we will find out that we were never abandoned, we were never forgotten. We will see the light in the end of that long tunnel and we will feel such happiness that we didn’t even think was possible. ",
  },
  {
    id: "painting8",
    name: "Forgotten",
    size: "20x25",
    image: p8,
    paintingType: "Watercolor on paper",
    year: 2019,
    price: 69.99,
    priceInt: 159.99,
  },
  {
    id: "painting7",
    name: "Night Sky",
    size: "30x40",
    image: p7,
    paintingType: "Acrylic on Canvas",
    year: 2017,
    price: 109.99,
    priceInt: 249.99,
  },
  {
    id: "painting3",
    name: "Horse Painting",
    size: "50x70",
    image: p3,
    paintingType: "Acrylic and mixed media on canva",
    year: 2020,
    price: 319.99,
    priceInt: 979.99,
    description:
      "Part of the “Horses” series, this is the most elevated painting of all. Carefully crafted and executed, it resembles the feeling of pride. A little pride is necessary, but too much of it can cause serious harm around us. The horse- representing the stiff and prideful nature, fades away into a softer, vulnerable creature, leaving a sweetness everywhere it wanders.",
  },

  {
    id: "painting4",
    name: "Horse Painting 2",
    size: "30x40",
    image: p4,
    paintingType: "Acrylic on canva",
    year: 2019,
    price: 139.99,
    priceInt: 309.99,
    description: "Part of the “Horses” series.",
  },
  {
    id: "painting5",
    name: "Horse Painting 3",
    size: "35x50",
    image: p5,
    paintingType: "Acrylic on canva",
    year: 2019,
    price: 179.99,
    priceInt: 379.99,
    description: "Part of the “Horses” series.",
  },
  {
    id: "painting11",
    name: "Artifact",
    size: "80x100",
    image: p11,
    paintingType: "Acrylic on Canvas",
    year: 2021,
    price: 409.99,
    priceInt: 849.99,
    description:
      "It’s very important to preserve the culture and work on keeping the old alive. Finding art- sculptures, paintings or building remains of the past can awaken in you something you didn’t know existed. Sometimes a certain piece just speaks to you and when it does, listen carefully    ",
  },
];

export default paintingsData;
