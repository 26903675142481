import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bt1 from "../assets/btsAssets/bt1.jpg";
import bt2 from "../assets/btsAssets/bt2.jpg";
import bt3 from "../assets/btsAssets/bt3.jpg";
import bt4 from "../assets/btsAssets/bt4.jpg";
import bt5 from "../assets/btsAssets/bt5.jpg";
import bt6 from "../assets/btsAssets/bt6.jpg";
import bt7 from "../assets/btsAssets/bt7.jpg";
import bt8 from "../assets/btsAssets/bt8.jpg";
import bt9 from "../assets/btsAssets/bt9.jpg";
import bt10 from "../assets/btsAssets/bt10.jpg";
import bt11 from "../assets/btsAssets/bt11.jpg";
import bt12 from "../assets/btsAssets/bt12.jpg";
import bt13 from "../assets/btsAssets/bt13.jpg";
import bt14 from "../assets/btsAssets/bt14.jpg";
import bt15 from "../assets/btsAssets/bt15.jpg";
import bt16 from "../assets/btsAssets/bt16.jpg";
import bt17 from "../assets/btsAssets/bt17.jpg";
import bt18 from "../assets/btsAssets/bt18.jpg";
import bt19 from "../assets/btsAssets/bt19.jpg";
import bt20 from "../assets/btsAssets/bt20.jpg";
import bt21 from "../assets/btsAssets/bt21.jpg";
import bt22 from "../assets/btsAssets/bt22.jpg";
import bt23 from "../assets/btsAssets/bt23.jpg";
import bt25 from "../assets/btsAssets/bt25.jpg";
import bt26 from "../assets/btsAssets/bt26.jpg";
import bt27 from "../assets/btsAssets/bt27.jpg";
import bt28 from "../assets/btsAssets/bt28.jpg";
import { motion, useAnimation, useInView } from "framer-motion";

const BTSPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Behind The Scenes | Pouring Passions";
  }, []);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.h1
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.5 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        Behind the scenes of true paint mastery
      </motion.h1>
      <motion.div
        className="cols"
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.5 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
      >
        <div className="col">
          <img src={bt1} alt="" />
          <img src={bt2} alt="" />
          <img src={bt3} alt="" />
          <img src={bt4} alt="" />
          <img src={bt5} alt="" />
          <img src={bt6} alt="" />
          <img src={bt7} alt="" />
          <img src={bt8} alt="" />
          <img src={bt9} alt="" />
        </div>
        <div className="col">
          <img src={bt11} alt="" />
          <img src={bt12} alt="" />
          <img src={bt13} alt="" />
          <img src={bt14} alt="" />
          <img src={bt15} alt="" />
          <img src={bt16} alt="" />
          <img src={bt17} alt="" />
          <img src={bt18} alt="" />
          <img src={bt19} alt="" />
        </div>
        <div className="col col3">
          <img src={bt20} alt="" />
          <img src={bt21} alt="" />
          <img src={bt22} alt="" />
          <img src={bt10} alt="" />
          <img src={bt23} alt="" />
          <img src={bt25} alt="" />
          <img src={bt26} alt="" />
          <img src={bt27} alt="" />
          <img src={bt28} alt="" />
        </div>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  h1 {
    font-weight: 200;
    text-align: center;
    text-transform: uppercase;
    color: var(--fontPrimaryColor);
    font-size: 7vw;
    font-weight: 200;
    font-family: "The Seasons", serif;
  }
  .cols {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-left: 2%;
    margin-top: 3em;
    .col {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 30%;
      img {
        width: 100%;
      }
    }
  }
  @media (max-width: 750px) {
    .cols {
      flex-wrap: wrap;
      .col {
        width: 47%;
      }
      .col3 {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        img {
          width: 47%;
        }
      }
    }
  }
  @media (max-width: 600px) {
    h1 {
      font-size: 12vw;
    }
  }
`;

export default BTSPage;
