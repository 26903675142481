import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home.jsx";
import Navbar from "./components/Navbar.jsx";
import Paintings from "./routes/Paintings.jsx";
import ProductPage from "./routes/ProductPage.jsx";
import Murals from "./routes/Murals.jsx";
import Footer from "./components/Footer.jsx";
import ContactPage from "./routes/ContactPage.jsx";
import AboutMe from "./routes/AboutMe.jsx";
import CheckoutPage from "./routes/CheckoutPage.jsx";
import BTSPage from "./routes/BTSPage.jsx";
import ReactGA from "react-ga";

function App() {
  ReactGA.initialize("G-QRR7JGQ2K9");
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/paintings" element={<Paintings />} />
        <Route path="/murals" element={<Murals />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/behind-the-scenes" element={<BTSPage />} />
        <Route path="/paintings/:name" element={<ProductPage />} />
        <Route path="/checkout/:name" element={<CheckoutPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
