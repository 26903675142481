import React from "react";
import styled from "styled-components";
import logo from "../../assets/newLogo.png";

const Quotes = ({ theme }) => {
  return (
    <Container>
      <h1>PASSION</h1>
      <h1>Storytelling</h1>
      <h1>POETRY</h1>
      <h1>Creativity</h1>
      <h1>moments</h1>
      <h1>
        <img src={logo} alt="" />
      </h1>
    </Container>
  );
};
const Container = styled.div`
  padding: 5em 7% 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10em;
  overflow-x: visible;
  h1 {
    font-weight: 200;
    font-size: 8vw;
    font-family: "The Seasons" sans-serif;
    text-transform: uppercase;
    position: sticky;
    top: 0.7em;
    -webkit-text-fill-color: transparent;
    background-image: var(--bgTextGradient2);
    -webkit-background-clip: text;
    background-clip: text;
    img {
      width: 70%;
    }
    &:nth-child(2) {
      top: 1.7em;
    }
    &:nth-child(3) {
      top: 2.7em;
    }
    &:nth-child(4) {
      top: 3.7em;
    }
    &:nth-child(5) {
      top: 4.7em;
    }
    &:nth-child(6) {
      padding-top: 20px;
      top: 7em;
      width: 100%;
      background: white;
      text-align: center;
      color: var(--primaryColor);
    }
  }
  @media (max-width: 1070px) {
    h1 {
      top: 2em;
      &:nth-child(2) {
        top: 3em;
      }
      &:nth-child(3) {
        top: 4em;
      }
      &:nth-child(4) {
        top: 5em;
      }
      &:nth-child(5) {
        top: 6em;
      }
      &:nth-child(6) {
        top: 7em;
        width: 100%;
        background-color: var(--primaryBackgroundColor);
        text-align: center;
      }
    }
  }
  @media (max-width: 450px) {
    h1 {
      font-size: 11vw;
      top: 3.3em;
      &:nth-child(2) {
        top: 4.3em;
      }
      &:nth-child(3) {
        top: 5.3em;
      }
      &:nth-child(4) {
        top: 6.3em;
      }
      &:nth-child(5) {
        top: 7.3em;
      }
      &:nth-child(6) {
        top: 8.3em;
        width: 100%;
        text-align: center;
        img {
          width: 100%;
        }
      }
    }
  }
`;

export default Quotes;
