import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const Stats = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.5 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        <h1>HANDMADE</h1>
        <h3>With love</h3>
      </motion.div>
      <hr />
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
      >
        <h1>CRAFTED</h1>
        <h3>with passion</h3>
      </motion.div>
      <hr />
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
      >
        <h1>OVER 50</h1>
        <h3>Paintings Sold</h3>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 7% 4em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  overflow-x: hidden;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    h1 {
      font-size: 1.5em;
      font-weight: 600;
      color: #3d2f2f;
    }
    h3 {
      font-weight: 400;
      color: #6e6262;
      text-align: center;
    }
  }
  hr {
    height: 100px;
  }
  @media (max-width: 650px) {
    gap: 1em;
  }
  @media (max-width: 500px) {
    gap: 1em;
    flex-direction: column;
    hr {
      width: 90%;
      height: 1px;
    }
    div {
      width: 80%;
    }
  }
`;

export default Stats;
