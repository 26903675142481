import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bts1 from "../../assets/homeAssets/btsImg1.JPG";
import bts2 from "../../assets/homeAssets/btsImg2.jpg";
import bts3 from "../../assets/homeAssets/btsImg3.jpg";
import bts4 from "../../assets/homeAssets/btsImg4.jpg";
import bts5 from "../../assets/homeAssets/btsImg5.jpg";
import bts6 from "../../assets/homeAssets/btsImg6.jpg";
import bts7 from "../../assets/homeAssets/btsImg7.jpg";
import bts8 from "../../assets/homeAssets/btsImg8.jpg";
import bts9 from "../../assets/homeAssets/btsImg9.jpg";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";

const BTS = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <h1>Behind The Scenes</h1>
      <div className="cols">
        <div className="col">
          <motion.img
            ref={ref}
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
            src={bts1}
            alt="BTS img 1"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
            src={bts2}
            alt="BTS img 2"
          />
        </div>
        <div className="col">
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.7, type: "spring" }}
            src={bts6}
            alt="BTS img 1"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
            src={bts4}
            alt="BTS img 2"
          />
        </div>
        <div className="col">
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 1.1, type: "spring" }}
            src={bts3}
            alt="BTS img 5"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 1.3, type: "spring" }}
            src={bts5}
            alt="BTS Image 6"
          />
        </div>
        <div className="col">
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 1.1, type: "spring" }}
            src={bts7}
            alt="BTS img 5"
          />
          <motion.img
            variants={{
              hidden: { opacity: 0, scale: 0.5 },
              visible: { opacity: 1, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 1.3, type: "spring" }}
            src={bts8}
            alt="BTS Image 6"
          />
        </div>
      </div>
      <Link to={"/behind-the-scenes"} className="link-styles">
        <button>SEE MORE</button>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 7em 7%; */
  padding: 7em 5%;
  overflow-x: hidden;
  h1 {
    font-weight: 200;
    font-size: 4.7em;
    font-family: "The Seasons", serif;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    background-image: var(--bgTextGradient2);
    -webkit-background-clip: text;
    background-clip: text;
    text-align: center;
  }
  .cols {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-left: 2%;
    .col {
      display: flex;
      flex-direction: column;
      gap: 1em;
      width: 30%;
      img {
        width: 100%;
      }
    }
  }
  button {
    display: flex;
    align-self: flex-start;
    margin: auto;
    color: #85766e;
    margin-top: 2em;
    padding: 10px 30px;
    background: none;
    border: 1px solid #b9aea7;
    padding: 17px;
    font-size: 18px;
    font-family: "Comfortaa", cursive;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      background-color: #85766e;
      color: white;
    }
  }
  @media (max-width: 750px) {
    .cols {
      flex-wrap: wrap;
      .col {
        width: 47%;
      }
    }
  }
`;

export default BTS;
