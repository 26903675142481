import m1 from "../assets/muralsAssets/m1.JPG";
import m2 from "../assets/muralsAssets/m2.JPG";
import m3 from "../assets/muralsAssets/m3.JPG";
import m4 from "../assets/muralsAssets/m4.JPG";
import m5 from "../assets/muralsAssets/m5.JPG";
import m6 from "../assets/muralsAssets/m6.JPG";
import m7 from "../assets/muralsAssets/m7.jpg";
import m8 from "../assets/muralsAssets/m8.jpg";
import m9 from "../assets/muralsAssets/m9.jpg";

const muralsData = [
  {
    mural: m9,
  },
  {
    mural: m8,
  },
  {
    mural: m1,
  },
  {
    mural: m2,
  },
  {
    mural: m7,
  },
  {
    mural: m3,
  },
  {
    mural: m4,
  },
  {
    mural: m5,
  },
  {
    mural: m6,
  },
];
export default muralsData;
