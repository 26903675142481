import React, { useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import img1 from "../assets/muralsAssets/m8.jpg";
import img2 from "../assets/muralsAssets/m7.jpg";
import img3 from "../assets/muralsAssets/m9.jpg";
import { Link } from "react-router-dom";
import muralsData from "../components/muralsData";

const Murals = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Murals | Pouring Passions";
  }, []);
  return (
    <Container>
      <Swiper
        className="swiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={4}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        <SwiperSlide className="slide">
          <div className="left">
            <h1>Walls are just canvas in disguise.</h1>
            <p>Turning ordinary walls into masterpieces!</p>
          </div>
          <img src={img1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide2">
          <div className="left">
            <h1>Bringing walls to life, one mural at a time.</h1>
            <p>Creating stories on every surface!</p>
          </div>
          <img src={img2} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide slide3">
          <div className="left">
            <h1>Leave no wall behind - let's muralize!</h1>
            <p>Every wall deserves a touch of artistry!</p>
          </div>
          <img src={img3} alt="" />
        </SwiperSlide>
      </Swiper>

      <div className="murals">
        {muralsData.map((item) => (
          <div className="item">
            <img src={item.mural} alt="" />
          </div>
        ))}
      </div>
    </Container>
  );
};
const Container = styled.div`
  margin: 5em 0;
  .swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .slide {
      padding: 5% 2%;
      width: 90%;
      height: 70vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      gap: 8%;
      background: #86696928;
      .left {
        width: 45%;
        h1 {
          color: #464646;
          font-size: 6vw;
          font-family: "The Seasons", sans-serif;
          font-weight: 300;
        }
      }
      img {
        height: 100%;
        max-width: 50%;
        object-fit: contain;
      }
    }
  }
  .murals {
    padding: 7em 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em 2em;
    flex-wrap: wrap;
    .item {
      height: 90vh;
      img {
        object-fit: contain;
        height: 90vh;
        /* height: 80%;
        max-height: 600px;
        min-width: 30%; */
        cursor: pointer;
        transition: ease-in-out 0.4s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 5%;
    height: 5px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #ada491; /* Change to your desired active color */
    opacity: 1;
  }
  @media (max-width: 600px) {
    .swiper {
      height: auto;
      .slide {
        height: 80vh;
        gap: 3%;
        flex-direction: column;
        .left {
          width: 100%;
          text-align: center;
          h1 {
            font-size: 8vw;
          }
        }
        img {
          height: auto;
          max-height: 60%;
          max-width: 100%;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .swiper {
      .slide {
        width: 100%;
        height: 60vh;
        .left {
          h1 {
            font-size: 10vw;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .murals {
      .item {
        height: auto;
        width: 100%;
        img {
          height: auto;
          width: 100%;
          min-width: 100px;
        }
      }
    }
  }
`;

export default Murals;
