import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import palette from "../../assets/homeAssets/palette.png";
import { Link } from "react-router-dom";

const NewBanner = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      {/* <img src={brushImg} className="img1" alt="" /> */}
      <span>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.5 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          How many times have people used a pen or a paintbrush, because they
          couldn't pull the trigger?
        </motion.h1>
        <motion.p
          variants={{
            hidden: { opacity: 0, y: 100, scale: 0.6 },
            visible: { opacity: 1, y: 0, scale: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
        >
          - Virginia Woolf
        </motion.p>
        <Link to={"/paintings"} className="link-styles">
          <motion.button
            variants={{
              hidden: { opacity: 0, y: 100, scale: 0.6 },
              visible: { opacity: 1, y: 0, scale: 1 },
            }}
            initial="hidden"
            animate={animation}
            transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
          >
            EXPLORE ART
          </motion.button>
        </Link>
      </span>
      <motion.img
        src={palette}
        alt=""
        variants={{
          hidden: { opacity: 0, y: "100%", rotate: "370deg" },
          visible: { opacity: 0.15, y: 0, rotate: "160deg" },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 1, delay: 0.9, type: "spring" }}
      />
      {/* <img src={brushImg} className="img2" alt="" /> */}
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    position: absolute;
    vertical-align: center;
    right: 18%;
    margin-top: 61%;
    width: 60%;
    z-index: 0;
    opacity: 0.1;
    transform: rotate(160deg);
  }
  .img1 {
    position: absolute;
    left: 10%;
    transform: rotate(50deg);
    width: 30vw;
  }
  .img2 {
    position: absolute;
    width: 30vw;
    bottom: -18%;
    right: 10%;
    transform: rotate(196deg);
  }
  span {
    height: 100%;
    z-index: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    align-items: center;
    h1 {
      font-size: 5.4vw;
      font-family: "The Seasons";
      font-weight: 200;
      -webkit-text-fill-color: transparent;
      background-image: var(--bgTextGradient);
      -webkit-background-clip: text;
      background-clip: text;
      margin-top: 2%;
      z-index: 1;
      text-align: center;
    }
    p {
      z-index: 1;
      font-size: 18px;
      margin-top: 10px;
    }
    button {
      background: #ffffff50;
      backdrop-filter: blur(6px);
      color: #574e4e;
      font-family: "Josefin Sans", sans-serif;
      padding: 17px 20px 15px 20px;
      font-size: 20px;
      border: 1px solid #574e4e;
      margin-top: 1em;
      z-index: 1;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #574e4e;
        color: white;
      }
    }
  }
  @media (max-width: 1000px) {
    img {
      width: 90%;
      right: 5%;
    }
    span {
      h1 {
        font-size: 7vw;
      }
    }
  }
  @media (max-width: 650px) {
    span {
      h1 {
        font-size: 11vw;
      }
    }
  }
`;

export default NewBanner;
