import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import paintingsData from "../components/paintingsData";
import { motion, useAnimation, useInView } from "framer-motion";

const ProductPage = () => {
  const { name } = useParams();
  const [mainImage, setMainImage] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = `${name} | Pouring Passions`;
  }, []);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  useEffect(() => {
    const product = paintingsData.find((item) => item.name === name);
    if (product) {
      setMainImage(product.image);
    }
  }, [name]);
  return (
    <Container>
      <>
        {paintingsData
          .filter((item) => item.name === name)
          .map((product) => (
            <>
              <div className="imageDiv">
                {product.unavailable ? (
                  <div className="img">
                    <img src={product.image} alt="" />
                    <h2>Out Of Stock</h2>
                  </div>
                ) : (
                  <>
                    <motion.img
                      ref={ref}
                      variants={{
                        hidden: { opacity: 0, y: 100, scale: 0.5 },
                        visible: { opacity: 1, y: 0, scale: 1 },
                      }}
                      initial="hidden"
                      animate={animation}
                      transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
                      src={mainImage}
                      alt=""
                    />
                    <div className="gallery">
                      {product.images &&
                        product.images.map((image, index) => (
                          <img
                            key={index}
                            src={image}
                            alt={`${product.name} ${index + 1}`}
                            onClick={() => setMainImage(image)}
                            className={`thumbnail ${
                              image === mainImage ? "active" : ""
                            }`}
                          />
                        ))}
                    </div>
                  </>
                )}
              </div>
              <motion.div
                className="right"
                variants={{
                  hidden: { opacity: 0, x: 100 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={animation}
                transition={{ duration: 0.6, delay: 0.5, type: "spring" }}
              >
                <span>
                  Paintind by:
                  <h4>Elsa Hoti</h4>
                </span>
                <h2>{product.name}</h2>
                {product.size && <h3>{product.size}cm</h3>}
                <h1>€{product.price}</h1>
                <h3>
                  {product.unavailable ? (
                    <span className="outOfStock">Out of Stock</span>
                  ) : (
                    <span> In Stock</span>
                  )}
                </h3>
                <p>{product.description}</p>
                <div className="btns">
                  {product.sold ? (
                    <>Note: This painting has been sold!</>
                  ) : (
                    <Link to={`/checkout/${product.name}`}>
                      <button>BUY NOW</button>
                    </Link>
                  )}
                </div>
              </motion.div>
            </>
          ))}
      </>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  color: #81745a;
  transition: 0.5s;
  .imageDiv {
    width: 60%;
    max-height: 80vh;
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 1em;
    transition: 0.5s;
    img {
      width: 89%;
      max-height: 650px;
      object-fit: contain;
      transition: 0.5s;
    }
    .gallery {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 2em;
      transition: 0.5s;
      img {
        width: 6vw;
        transition: 0.5s;
      }
    }
  }
  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
      font-weight: 500;
      color: #81745a;
      h4 {
        font-weight: 300;
      }
    }
    h2 {
      font-size: 2em;
      font-family: "Josefin Sans", sans-serif;
      color: #81745a;
    }
    h3 {
      font-weight: 400;
      span {
        color: #81745a;
      }
    }
    h1 {
      font-size: 2em;
      color: #81745a;
      font-weight: 400;
    }
    p {
      color: #b3a698;
    }
    button {
      margin-top: 1em;
      font-family: "Montserrat", sans-serif;
      color: #85766e;
      font-size: 1.2em;
      padding: 10px 30px;
      background: none;
      border: 1px solid #b9aea7;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #81745a;
        color: white;
      }
    }
  }
  @media (max-width: 730px) {
    flex-direction: column;
    .right {
      text-align: center;
      align-items: center;
      width: 100%;
    }
    .imageDiv {
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .gallery {
        flex-direction: row;
        img {
          width: 20%;
        }
      }
    }
  }
  @media (max-width: 550px) {
    .imageDiv {
      /* img {
        height: 60vh;
        width: auto;
      } */
      .gallery {
        img {
          width: auto;
          height: 70px;
        }
      }
    }
  }
  @media (max-width: 430px) {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`;

export default ProductPage;
